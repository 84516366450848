import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const config = {
  apiKey: "AIzaSyCjoCQpxyqtkRw1nEk8hBNC_aemYAuUJt0",
  authDomain: "insta-clone-573f7.firebaseapp.com",
  projectId: "insta-clone-573f7",
  storageBucket: "insta-clone-573f7.appspot.com",
  messagingSenderId: "234505442329",
  appId: "1:234505442329:web:d46dfcef6ef449ebccc688",
};

const app = initializeApp(config);

const messaging = getMessaging(app);

export const getDeviceToken = () => {
  return getToken(messaging)
    .then((value) => {})
    .catch((err) => {});
};
