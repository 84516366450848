import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllTakeRateApprovalHandler, fetchAllTakeRateApprovedHandler, fetchAllTakeRateRejectedHandler } from "./takeRate.thunk";

const initialState = {
  TakeRateApprovalData: [],
  totalTakeRateapprovalData:0,
  TakeRateApprovedData: [],
  totalTakeRateapprovedData:0,
  TakeRateRejectedData: [],
  totalTakeRateRejectedData:0,
};



export const fetchAllTakeRateApprovalThunk = createAsyncThunk(
  "fetchTakeApproval",
  fetchAllTakeRateApprovalHandler
);
export const fetchAllTakeRateApprovedThunk = createAsyncThunk(
  "fetchTakeApproved",
  fetchAllTakeRateApprovedHandler
);
export const fetchAllTakeRateRejectedThunk = createAsyncThunk(
  "fetchTakeRejected",
  fetchAllTakeRateRejectedHandler
);

const takeRateApprovalSlice = createSlice({
  name: "takeRateApproval",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(fetchAllTakeRateApprovalThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllTakeRateApprovalThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.TakeRateApprovalData = action.payload.data;
        state.totalTakeRateapprovalData = action.payload.total;
        return state;
      })
      .addCase(fetchAllTakeRateApprovalThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllTakeRateApprovedThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllTakeRateApprovedThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.TakeRateApprovedData = action.payload.data;

        state.totalTakeRateapprovedData = action.payload.total;

        return state;
      })
      .addCase(fetchAllTakeRateApprovedThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllTakeRateRejectedThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllTakeRateRejectedThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.TakeRateRejectedData = action.payload.data;
        state.totalTakeRateRejectedData = action.payload.total;

        return state;
      })
      .addCase(fetchAllTakeRateRejectedThunk.rejected, (state) => {
        state.isLoading = false;
      })
      
  },
});

export default takeRateApprovalSlice.reducer;
