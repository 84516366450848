import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useStyles } from "../Section.theme";
import { CONSOLE_LEVELS } from "@sentry/utils";
import ja from "date-fns/locale/ja/index";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: "15px",
    alignItems: "center",
    padding: "16px 20px",
    borderRadius: "12px",
    backgroundColor: " #f9f9f9",
  },
}));

function TextInput({
  value,
  status,
  columns,
  change,
  styles,
  addRowExtra = {},
  id,
  type,
  dispalyIconProperty = "block",
  isAdmin,
  ...rest
}) {
  const { icon, onClick } = addRowExtra;
  const [isVisible, setIsVisible] = useState(true);
  const [dropStatus, setDropStatus] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (columns != undefined) {
      for (var i = 0; i < Object.keys(columns).length; i++) {
        if (
          columns[Object.keys(columns)[i]].value.toUpperCase() === "STATUS" &&
          columns[Object.keys(columns)[i]].type.toUpperCase() === "DROPDOWN"
        ) {
          for (var j = 0; j < Object.keys(status).length; j++) {
            if (Object.keys(status)[j] == Object.keys(columns)[i]) {
              setDropStatus(status[Object.keys(status)[j]].value);
            }
          }
        }
      }
    }
  }, [columns, status]);

  // function fncOnFocus() {
  //   setIsVisible(false);
  // }
  // function fncOnBlur() {
  //   setIsVisible(true);
  // }

  // function MouseOver() {
  //   setIsVisible(true);
  // }
  // function MouseOut() {
  //   setIsVisible(false);
  // }
  function fncOnChangeClick(e) {
    setIsVisible(false);
  }
  function fncOnChange(e) {
    change(e.target.value);
    var textarea = document.getElementById(e.target.id);
    if (textarea != null) {
      textarea.addEventListener("keydown", autoResize, false);
      textarea.addEventListener("focusout", fncFocusOut, false);

      function fncFocusOut() {
        setIsVisible(true);
        this.style.height = "50px";
      }
      function autoResize() {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      }
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <>
        {dropStatus === "Done" && isAdmin == false ? (
          <p
            className={classes.disabledInputElement}
            style={{ paddingTop: "5px", lineHeight: "1.2" }}
          >
            {value}
          </p>
        ) : (
          <>
            {type === "text" ? (
              isVisible === true ? (
                <LightTooltip
                  disableFocusListener
                  disableTouchListener
                  title={value}
                >
                  <textarea
                    row="10"
                    id={id}
                    className={classes.inputElement}
                    value={value}
                    onChange={fncOnChange}
                    style={styles}
                    onClick={fncOnChangeClick}
                  />
                </LightTooltip>
              ) : (
                <>
                  <textarea
                    row="10"
                    id={id}
                    className={classes.inputElement}
                    value={value}
                    onChange={fncOnChange}
                    style={styles}
                  />
                </>
              )
            ) : (
              <input
                id={id}
                className={classes.inputElement}
                value={value}
                onChange={(e) => change(e.target.value)}
                style={styles}
              />
            )}
            {icon && (
              <div
                style={{
                  position: "absolute",
                  right: "3px",
                  top: "10px",
                  cursor: "pointer",
                  display: dispalyIconProperty,
                }}
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(rest);
                  }}
                >
                  {icon}
                </span>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default TextInput;
