import React, { useState, useEffect } from "react";
import { styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useStyles } from "../Section.theme";
import { CONSOLE_LEVELS } from "@sentry/utils";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: "15px",
    alignItems: "center",
    padding: "16px 20px",
    borderRadius: "12px",
    backgroundColor: " #f9f9f9",
  },
}));

function TextInput({
  value,
  change,
  styles,
  addRowExtra = {},
  id,
  type,
  ...rest
}) {
  const { icon, onClick } = addRowExtra;
  const [isVisible, setIsVisible] = useState(true);
  const classes = useStyles();

  // function fncOnFocus() {
  //   setIsVisible(false);
  // }
  // function fncOnBlur() {
  //   setIsVisible(true);
  // }

  // function MouseOver() {
  //   setIsVisible(true);
  // }
  // function MouseOut() {
  //   setIsVisible(false);
  // }
  function fncOnChangeClick(e) {
    setIsVisible(false);
  }
  function fncOnChange(e) {
    change(e.target.value);
    var textarea = document.getElementById(e.target.id);
    if (textarea != null) {
      textarea.addEventListener("keydown", autoResize, false);
      textarea.addEventListener("focusout", fncFocusOut, false);

      function fncFocusOut() {
        setIsVisible(true);
        this.style.height = "50px";
      }
      function autoResize() {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      }
    }
  }

  return (
    <div style={{ position: "relative" }}>
      {type === "text" ? (
        isVisible === true ? (
          <LightTooltip disableFocusListener disableTouchListener title={value}>
            <textarea
              row="3"
              id={id}
              className={classes.inputElement}
              value={value}
              onChange={fncOnChange}
              style={styles}
              onClick={fncOnChangeClick}
              // onFocus={fncOnFocus}
              // onBlur={fncOnBlur}
              // onMouseOver={MouseOver}
              // onMouseOut={MouseOut}
            />
          </LightTooltip>
        ) : (
          <>
            <textarea
              row="3"
              id={id}
              className={classes.inputElement}
              value={value}
              onChange={fncOnChange}
              style={styles}
            />
          </>
        )
      ) : (
        <input
          id={id}
          className={classes.inputElement}
          value={value}
          onChange={(e) => change(e.target.value)}
          style={styles}
        />
      )}

      {/* {type === "text" ? (
        <LightTooltip disableFocusListener disableTouchListener title={value}>
          <textarea
            row="3"
            id={id}
            className={classes.inputElement}
            value={value}
            onChange={fncOnChange}
            style={styles}
          />
        </LightTooltip>
      ) : (
        <input
          id={id}
          className={classes.inputElement}
          value={value}
          onChange={(e) => change(e.target.value)}
          style={styles}
        />
      )} */}

      {icon && (
        <div
          style={{
            position: "absolute",
            right: "3px",
            top: "10px",
            cursor: "pointer",
          }}
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              onClick(rest);
            }}
          >
            {icon}
          </span>
        </div>
      )}
    </div>
  );
}

export default TextInput;
